import { Helmet } from 'react-helmet';
import styles from './index.module.css';

function EventsPage() {
  return (
    <div className={styles.eventsPage}>
        <Helmet>
            <title>Shows</title>
        </Helmet>
        <h4>you can find all upcoming shows here</h4>
        <a href='https://www.instagram.com/phonobarsf/' target="_blank" rel="noreferrer">
            <button className={styles.eventButton}><h5>5/11 - Phonobar</h5></button>
        </a>
        <a href='https://www.instagram.com/thefoundrysf/' target="_blank" rel="noreferrer">
            <button className={styles.eventButton}><h5>7/19 - Felipe Gordon x Lossless Wax Foundry residency</h5></button>
        </a>
    </div>
  )
}
export default EventsPage;
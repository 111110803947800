import styles from './index.module.css';
import Episode from '../Episode';
import Episode1 from '../../content/episodes/episode1/index.json';
import Episode2 from '../../content/episodes/episode2/index.json';
import Episode3 from '../../content/episodes/episode3/index.json';
// import Episode4 from '../../content/episodes/episode4/index.json';
// import Episode5 from '../../content/episodes/episode5/index.json';
// import Episode6 from '../../content/episodes/episode6/index.json';
// import Episode7 from '../../content/episodes/episode7/index.json';
import Episode8 from '../../content/episodes/episode8/index.json';
import Episode9 from '../../content/episodes/episode9/index.json';
import Episode10 from '../../content/episodes/episode10/index.json';
// import Episode11 from '../../content/episodes/episode11/index.json';
import Episode12 from '../../content/episodes/episode12/index.json';
import Episode13 from '../../content/episodes/episode13/index.json';
import Episode14 from '../../content/episodes/episode14/index.json';
import Episode15 from '../../content/episodes/episode15/index.json';
import Episode16 from '../../content/episodes/episode16/index.json';
import Episode17 from '../../content/episodes/episode17/index.json';
import Episode18 from '../../content/episodes/episode18/index.json';
import { Helmet } from 'react-helmet';

const episodeList = [
    Episode18,
    Episode17,
    Episode16,
    Episode15,
    Episode14,
    Episode13,
    Episode12,
    // Episode11,
    Episode10,
    Episode9,
    Episode8,
    // Episode7,
    // Episode6,
    // Episode5,
    // Episode4,
    Episode3,
    Episode2,
    Episode1
];

function Sets() {
  return (
    <div className={styles.sets}>
        <Helmet>
            <title>Sets</title>
        </Helmet>
        <h5 className={styles.quality}>*all sets are recorded and mastered in high quality stereo format*</h5>
        <section>
            {
                episodeList.map((episode) => {
                    return (
                        <div>
                            <Episode
                                key={episode.number}
                                number={episode.number}
                                title={episode.title}
                                description={episode.description}
                                date={episode.date}
                                location={episode.location}
                                tracklist={episode.tracklist}
                                url={episode.url}
                                coverURL={episode.coverURL}
                                isExternal={episode.isExternal || false}
                            />
                            <div className={styles.line}/>
                        </div>
                    );
                })
            }
        </section>
    </div>
  )
}
export default Sets;
import styles from './index.module.css';

function Header() {
  return (
    <header className={styles.header}>
        <a href='/'><h1>Lossless Wax</h1></a>
        <h2>curating genre agnostic music on vinyl</h2>
        <div className={styles.line} />
    </header>
  )
}
export default Header;
import { useState } from 'react';
import { IconButton} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './index.module.css';

function Episode(props) {
    const {number, title, description, date, location, tracklist, url, coverURL, isExternal} = {...props};
    const [trackListOpen, settrackListOpen] = useState(false)
    return (
        <div className={styles.episode}>
            <h4><span className={styles.episodeNumber}>Episode {number}:</span> {title}</h4>
            <section className={styles.videoMetaData}>
                <h5>{location}</h5>
                <h5>{date}</h5>
            </section>
            <h5>{description}</h5>
            {
                isExternal && 
                <iframe 
                    src={url} 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen="true"
                    className={styles.iframe}
                ></iframe>
            }
            {
                !isExternal &&
                <video controls title={title} poster={coverURL} style={{backgroundImage: coverURL}}>
                    <source src={url} type='video/mp4' />
                </video>

            }
            <details open={trackListOpen}>
                <summary>
                    <h5>Tracklist</h5>
                    <IconButton
                        onClick={() => settrackListOpen(!trackListOpen)}
                        className={styles.ExpandButton}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </summary>
                {
                    tracklist.map((track, index) => {
                        return (
                            <h5 key={index}>{`${index + 1}) ${track}`}</h5>
                        )
                    })
                }
            </details>
        </div>
    )
}
export default Episode;
import { Container } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router-dom';
// import Albums from './components/Albums';
import Footer from './components/Footer';
import Header from './components/Header';
import Sets from './components/Sets';
import Welcome from './components/Welcome';
// import AlbumPage from './components/AlbumPage';
import EventsPage from './components/EventsPage';
// import PicturesPage from './components/PicturesPage';
import SpecialPage from './components/SpecialPage';
// import Contact from './components/Contact';
import styles from './App.module.css';

function App() {
  return (
    <div className={styles.app}>
      <Container disableGutters maxWidth='md' className={styles.container}>
        <Header />
        <main>
          <Routes>
            <Route
              path='/'
              element={<Welcome />}
            />
            <Route
              path='/sets'
              element={<Sets />}
            />
            <Route
              path='/shows'
              element={<EventsPage />}
            />
            {/* <Route
              path='/pictures'
              element={<PicturesPage />}
            /> */}
            {/* <Route
              path='/residency'
              element={<SpecialPage/>}
            /> */}
            {/* <Route
              path='/warehouse-party'
              element={<Warehouse />}
            /> */}
            {/* <Route
              path='/albums'
              element={<Albums />}
            /> */}
            {/* <Route
              path='/albums/:albumID'
              element={<AlbumPage />}
            /> */}
            {/* <Route
              path='/about'
              element={<About />}
            /> */}
            <Route
              path='/signup'
              element={<SpecialPage />}
            />

            {/* Catch all default route */}
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </main>
        <Footer />
      </Container>
    </div>
  );
}

export default App;

import React from 'react'
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import styles from './index.module.css';

const LAMBDA_URL = 'https://frzhv6wr9k.execute-api.us-west-1.amazonaws.com/default/lambda_collect_contact_info';

function SpecialPage() {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isError, setIsError] = useState(false);

    function validEmail(email) {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }
    function insertData() {
        if (!validEmail(email)) {
            setIsValid(false);
            return;
        }
        setIsValid(true);
        fetch(LAMBDA_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'email': email,
                'phoneNumber': phone,
            })
        }).then((res) => {
            if (res.status === 200) {
                setSubmitted(true)
            } else {
                throw Error(res);
            }
        }).catch((err) => {
            setIsError(err);
        })
    }
  return (
    <div className={styles.special}>
        <Helmet>
            <title>Signature Sounds Residency</title>
        </Helmet>

        <h4>signature sounds residency</h4>

        <ul>
            <li>
                <h5>
                    please check your prejudices, biases, problems, and negative energy at the door. we're here for the music.
                </h5>
            </li>
            <li>
                <h5>
                    there is no tolerance for harassment of any kind.
                </h5>
            </li>
            <li>
                <h5>
                    if you see something, say something! we'll deal with it immediately.
                </h5>
            </li>
            <li>
                <h5>
                    this series is dedicated to creating a safe and intimate dance floor that unites old heads with new ones through the shared love of house music.
                </h5>
            </li>
            <li>
                <h5>
                    we never play commercial music, don't take song requests, and only book selectors and creators that have mastered their craft through hours of musical curation and dedication.
                </h5>    
            </li>
            <li>
                <h5>
                be chill, have fun, dance hard, mingle with your dancefloor neighbors (with consent only), and protect your energy.
                </h5>    
            </li>
        </ul>
        <div className={styles.submissionDiv}>
        {
            !submitted &&
            <div>
            <h5 style={{textAlign:'center', textDecoration:'underline'}}>use the form below to sign up for updates and early ticket access</h5>
            <form>
                <input placeholder='email*' type='email' required value={email} onChange={(e) => setEmail(e.target.value)} />
                <input placeholder='phone number*' type='tel' required value={phone} onChange={(e) => setPhone(e.target.value)}/>
                <button type='button' onClick={insertData}>{(isValid && "submit") || (!isValid &&"invalid email")}</button>
            </form>
            </div>
        }
        {
            !submitted && isError &&
            <h4 className={styles.error}>we're having technical difficulties. try again later.</h4>
        }
        {
            submitted &&
            <h4 className={styles.error}>your submission has been received! we'll be in touch when tickets go on sale.</h4>
        }
        </div>
    </div>
  )
}
export default SpecialPage;